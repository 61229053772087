import React from 'react'
import { useState , useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import planContext from '../../context/plan/planContext';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
  
export default function Plan({plan,colWidth}) {

    const planDetails = useContext(planContext)
    
    
    const [state, setState] = useState("10");
    const [showCommission, setCommission] = useState("00.00");
    const [annualCommission, setAnnualCommission] = useState("");
    
    // eslint-disable-next-line
      useEffect(() => {
        let simAmount = plan.price;
        let noOfSims = state;
        let commission = 0.05;
        let totalCommission = (simAmount * noOfSims) * commission;
        setCommission(totalCommission.toFixed(2));
        setAnnualCommission((totalCommission * 12).toFixed(2))
     });

     
     
     // eslint-disable-next-line
    const handleChange = (event) => {
        setState(event.target.value, 10);
      }


    const handlePlanForm = (e) =>{
        e.preventDefault();
        const data = {
            numOfSim: e.target.elements.numOfSim.value,
        }
        let numberOfSim = data.numOfSim;
        planDetails.updateSimNum(numberOfSim);
        const objCommission = {
          'commission' : showCommission,
          'annualCommission' : annualCommission
        }
        planDetails.updateCommission(objCommission);
        routeChange()
      }
      let navigate = useNavigate(); 
        const routeChange = (e) =>{ 
        let path = `/order`; 
        navigate(path);
        }
      
     
      
  return (
    <div>
        <div className={`col-md-${colWidth} col-lg-${colWidth} col-md-${colWidth} col-xs-12 mb-3  text-dark`}>
                  <div className="plan ng-star-inserted py-3  px-2 mb-5 shadow ">
                        <h2 className="fw-bolder heading-color text-center heading-text">{plan.title} Data</h2>
                        <p className="text-center text-gray">Minimum 10 Sim Cards</p>
                        <div className="shadow-line mx-auto my-3"></div>
                        
                        <div className='fw-bold py-2'>
                            <div>
                                <p className='h2 text-center old-price fw-bolder text-gray'>£{plan.oldPrice}</p>
                            </div>    
                            <div className='text-center original-price'> 
                                <span className="text-green  heading-text my-2 mx-auto">£{plan.price}<small className='mr-3 text-gray regular-text '><span className="dot_price text-green"></span>  Monthly</small></span>
                            </div>
                        </div>
                        <div className="text-gray text-center mt-2">
                                {
                                plan.features.map((element)=>
                                <p>{element}</p>
                                )
                            }
                        </div>
                        <Form onSubmit={(e) => handlePlanForm(e)}>
                          <Form.Group as={Col} md="12" controlId="validationCustomUsername" className='position-relative'>
                            <Form.Label className='text-ligh-gray h4 label'>Number of SIMS</Form.Label>
                            <InputGroup hasValidation>
                              <Form.Control
                              className='w-100 my-3 form-control p-3 input'
                                name="numOfSim"
                                type="tel"
                                pattern="\d*" 
                                value={state}
                                placeholder="Enter Secret Pin here..."
                                aria-describedby="inputGroupPrepend"
                                onChange={(e) => handleChange(e)}
                                required
                              />
                            </InputGroup>
                            <input type="hidden" name="planName" value={plan.planId} />
                          </Form.Group>
                            <div className="text-gray text-center px-3 fw-normal">
                                <p className='small-text text-left'>You will receive <b>£{showCommission}</b> per month for the life of the SIM.</p>
                            </div>
                            
                            <div className="text-gray text-center p-3 fw-normal mt-2">
                                <h3 className='text-center'><b className='primary-color'>£{annualCommission}</b></h3>
                                <p><span className='small-text text-gray me-3'>Commission per anum</span></p>
                            </div>
                            <button  type="submit"   className="w-100 btn"onClick={()=>{planDetails.update(plan)}}>Order</button>   
                        </Form>
                  </div>
        </div>
    </div>
  )
}
