import React from 'react'
import Plan from "./Plan"

export default function SecretPlan() {
var plans = [
        {
                title: "3GB",
                features: [
                'Unlimited Minutes',
                'Unlimited Texts',
                '3GB Data',
                '5G Ready at no extra cost',
                'Personal Hotspot',
                'Go Roam Around the World',
                ],
                oldPriceIncVAT: "10.75",
                oldPrice: "12.90",
                priceIncVAT: "9.67",
                price: "11.60",
                planId: "3gb",
        },

        {
                title: "10GB",
                features: [
                'Unlimited Minutes',
                'Unlimited Texts',
                '10GB Data',
                '5G Ready at no extra cost',
                'Personal Hotspot',
                'Go Roam Around the World',
                ],
                oldPriceIncVAT: "14.62",
                oldPrice: "17.54",
                priceIncVAT: "13.16",
                price: "15.79",
                planId: "10gb",
        },
        {
                title: "30gb",
                features: [
                'Unlimited Minutes',
                'Unlimited Texts',
                '30GB Data',
                '5G Ready at no extra cost',
                'Personal Hotspot',
                'Go Roam Around the World',
                ],
                oldPriceIncVAT: "19.95",
                oldPrice: "23.94",
                priceIncVAT: "17.98",
                price: "21.58",
                planId: "30gb",
        },
        {
                title: "Unlimited",
                features: [
                'Unlimited Minutes',
                'Unlimited Texts',
                'Unlimited Data',
                '5G Ready at no extra cost',
                'Personal Hotspot',
                'Go Roam Around the World',
                ],
                oldPriceIncVAT: "23.75",
                oldPrice: "28.50",
                priceIncVAT: "21.37",
                price: "25.64",
                planId: "Unlimited",
        },
        ];
  return (
    <div className="container sim_plan py-5">
            <div className="text-center py-3">
                    <h2 className="heading-text text-center fw-bolder">24 Month SIM Only Plans</h2>
                    <p>Available to new and upgrading customers</p>
            </div>
        {
                plans.map((plan,index)=>
                        <Plan colWidth={3} key ={index} plan={plan} />
                )
        }

            {/* <Plan title="3GB" oldPrice="10.75" price="9.67" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  fieldName="sim_number" annualPrice="116.06" />

            <Plan title="10GB" oldPrice="14.62" price="13.16"
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  fieldName="sim_number" />
            
            <Plan title="30GB" oldPrice="19.95" price="17.98" 
                    features={
                            <>
                            <p>Unlimited Minutes</p>
                            <p>Unlimited Texts</p>
                            <p>3GB Data</p>
                            <p>5G Ready at no extra cost</p>
                            <p>Personal Hotspot</p>
                            <p>Go Roam Around the World</p>
                            </>
                    }  fieldName="sim_number" annualPrice="215.74"/>
            <Plan title="Unlimited" oldPrice="23.75" price="21.37" 
            features={
                    <>
                    <p>Unlimited Minutes</p>
                    <p>Unlimited Texts</p>
                    <p>3GB Data</p>
                    <p>5G Ready at no extra cost</p>
                    <p>Personal Hotspot</p>
                    <p>Go Roam Around the World</p>
                    </>
            }  fieldName="sim_number" annualPrice="256.44"/>*/}
    </div> 
  )
}
