import React, { useContext } from 'react'
import planContext from '../../context/plan/planContext'

export default function SelectedPlan({plan, colWidth,navigate}) {
    const planDetils = useContext(planContext)
    
    
  return (
    <div>
        <div className={`col-md-${colWidth} col-lg-${colWidth} col-md-${colWidth} col-xs-12 mb-3 text-dark sim_plan`}>
                  <div className="plan ng-star-inserted py-5  px-2 mb-5 shadow ">
                        <h2 className="fw-bolder heading-color text-center heading-text primary-color">{plan.title} Data</h2>
                        <p className="text-center text-gray">Minimum 10 Sim Cards</p>
                        <div className="shadow-line mx-auto my-3"></div>
                        
                        <div className='fw-bold py-2'>
                            
                            <div className='text-center original-price'> 
                                <span className="text-green  heading-text my-2 mx-auto">£{plan.price}<small className='mr-3 text-gray regular-text '><span className="dot_price text-green"></span>  Monthly</small></span>
                            </div>
                        </div>
                        <div className="text-gray text-center mt-2"  >
                       {
                        plan.features.map((element)=>
                            <p>{element}</p>
                        )
                       }
                        </div>
                        <p className='text-center'>Number of Sim: <b className='primary-color'>{planDetils.simNum}</b></p>
                       {
                        (() => {
                            if(!!(planDetils.commission.commission)){
                                return (
                                    <div>
                                        <div className="text-gray text-center px-3 fw-normal">
                                            <p className='small-text text-left'>You will receive <b className='primary-color'>£{planDetils.commission.commission}</b> per month for the life of the SIM.</p>
                                        </div>
                                        
                                        <div className="text-gray text-center p-3 fw-normal mt-2">
                                            <h3 className='text-center'><b className='primary-color'>£{planDetils.commission.annualCommission}</b></h3>
                                            <p><span className='small-text text-gray me-3'>Commission per anum</span></p>
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })()
                       }
                        
                        
                        <button className='btn' onClick={() => navigate(-1)}>Edit</button>
                        
                  </div>
        </div>
    </div>
  )
}
