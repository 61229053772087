import React from "react";
import Feature from "./Feature";

export default function HomePlan() {
  var plans = [
    {
      title: "3GB",
      features: [
        "Unlimited Minutes",
        "Unlimited Texts",
        "3GB Data",
        "5G Ready at no extra cost",
        "Personal Hotspot",
        "Go Roam Around the World",
      ],
      oldPrice: "10.75",
      price: "10.75",
      planId: "3gb",
    },
    {
      title: "10GB",
      features: [
        "Unlimited Minutes",
        "Unlimited Texts",
        "10GB Data",
        "5G Ready at no extra cost",
        "Personal Hotspot",
        "Go Roam Around the World",
      ],
      oldPrice: "14.62",
      price: "14.62",
      planId: "10gb",
    },
    {
      title: "30gb",
      features: [
        "Unlimited Minutes",
        "Unlimited Texts",
        "30GB Data",
        "5G Ready at no extra cost",
        "Personal Hotspot",
        "Go Roam Around the World",
      ],
      oldPrice: "19.95",
      price: "19.95",

      planId: "30gb",
    },
    {
      title: "Unlimited",
      features: [
        "Unlimited Minutes",
        "Unlimited Texts",
        "Unlimited Data",
        "5G Ready at no extra cost",
        "Personal Hotspot",
        "Go Roam Around the World",
      ],
      oldPrice: "23.75",
      price: "23.75",
      planId: "Unlimited",
    },
  ];

  return (
    <div className="container sim_plan py-5" id="home-plan">
      <div className="text-center py-5">
        <h2 className="heading-text text-center fw-bolder">
          24 Month SIM Only Plans
        </h2>
        <p>Available to new and upgrading customers</p>
      </div>
      
      {
        plans.map((plan,index)=>
        <Feature colWidth={3} key ={index} plan={plan} />
        )
      }
      
    </div>
  );
}
