import React, { useState } from 'react'

import PlanContext from './planContext';

const PlanState = (props) => {
    
    const [state, setState] = useState({});
    const [commission, setAnnualCom] = useState({});
    const [simNum, setSimNum] = useState();
    
    
    const update = (plan)=>{
        setState(plan)
    }
    const updateSimNum = (simNum) =>{
        
        setSimNum(simNum)
    }
    const updateCommission = (com) => {
        
        setAnnualCom(com)
    }
    return (
        <PlanContext.Provider value={{state,update, updateSimNum, simNum, updateCommission, commission}}>
            {props.children}
        </PlanContext.Provider>
    )
}

export default PlanState;
