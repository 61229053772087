import React from 'react'

export default function thankyou() {
  return (
    <div className='container py-5 text-center thankyou'>
      <h1>Thank You!</h1>
      <img className='thankyou-img' src='/images/high_five.jpg' alt='thankyou'/>
      <p>We have revcieved your contact details.</p>     
      <p>One of our manager will contact with you shortly.</p>
    </div>
  )
}
